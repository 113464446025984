import './index.scss';
import Router from './router';
import React, {useState, useCallback, useEffect} from 'react';
// import GlobalStateProvider './service/global-state';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { request, methods } from "./service/http-common";
 
// import { useNavigate } from "react-router-dom";

const GlobalStateProvider = React.lazy(() => import('./service/global-state'))
let timerFoIdle = undefined;
let idleDefaultTime = 1000 * 60 * 30;

function App() {
  // const navigate = useNavigate();
  let handleOnIdle = () => {
    const isLoggedIn = localStorage.getItem('loggedInUser')
    isLoggedIn && performLogout()
  }
  const performLogout = async () => {
    const [errorData, responseData] = await request(`user-mgmt/user/logout`, methods.DELETE);

      if (responseData) {

        localStorage.clear();

        const checkApp = window.location.href.indexOf("app");
        if(checkApp > 0){
          window.location.href = `/login`;
        }else{
          window.location.href = `/pwa-login`;
        }
      }
  }
  
  let resetTimer = () => {
    clearTimeout(timerFoIdle);
    timerFoIdle = setTimeout(handleOnIdle, idleDefaultTime)
    // 1000 milliseconds = 1 second
  }

  const InactivityTime = () => {
    window.onload = resetTimer;
    // DOM Events
    document.onclick = resetTimer;
    document.onkeydown = resetTimer;
    document.addEventListener('scroll', resetTimer, true); // improved; see comments
  };
  InactivityTime()


  return (
    <GlobalStateProvider>
      <div className="App">
        <Router />
        <ToastContainer autoClose={3000}/>
      </div>
    </GlobalStateProvider>
  );
}

export default App;
